<template>
  <div>
    <div class="recreation" id="recreation" v-show="lyData">
      <div class="search">
        <div class="city" @click="cityList">
          <img
            style="width: 13px; height: 16px"
            src="../../assets/location.png"
            alt=""
          />
          <!-- <van-icon color="#ff8757" name="location" /> -->
          <p>{{ locationCity.city }}</p>
          <img
            style="width: 10px; height: 6px"
            src="../../assets/down.png"
            alt=""
          />
        </div>
        <div class="input">
          <van-search
            clearable
            style="height: 35px"
            v-model="keyword"
            placeholder="搜索景点/商铺名"
            @search="changeInput"
          />
        </div>
      </div>
      <div class="extendDiv">
        <div class="extend">
          <div class="item">
            <img src="../../assets/play-img2.png" @click="lianlian" alt="" />
          </div>
          <div class="item">
            <img src="../../assets/play-img1.png" @click="xialv" alt="" />
          </div>
        </div>
        <div class="tipDiv">
          <div class="tips">
            <p>加入联盟后才有返现哦~自购省钱 推广赚钱</p>
          </div>
        </div>
      </div>

      <van-pull-refresh v-model="isDownLoading" @refresh="onDownRefresh">
        <van-list
          v-model="isUpLoading"
          :finished="upFinished"
          error-text="请求失败，点击重新加载"
          :immediate-check="false"
          finished-text="暂无数据"
          @load="onLoad"
        >
          <div class="list">
            <div
              class="item"
              @click="jumpUrl(item.cpsUrl)"
              v-for="(item, index) in lyData"
              :key="index"
            >
              <div class="image">
                <img :src="item.coverImg" alt="" />
                <div
                  class="collectionDiv"
                  v-if="!item.isFavorite"
                  @click.stop="collection(item)"
                >
                  <img src="../../assets/collection-icon.png" alt="" />
                  <p>收藏</p>
                </div>
                <div
                  class="collectionDiv"
                  v-else
                  @click.stop="cancelCollection(item)"
                >
                  <img src="../../assets/collection-icon-active.png" alt="" />
                  <p>已收藏</p>
                </div>
              </div>
              <div
                :class="
                  item.status === 0
                    ? 'title status1'
                    : item.status === 1
                    ? 'title status2'
                    : 'title status2'
                "
              >
                <p>
                  {{ item.title }}
                </p>
              </div>
              <div
                :class="
                  item.status === 0
                    ? 'name status1'
                    : item.status === 1
                    ? 'name status2'
                    : 'name status2'
                "
              >
                <p>
                  {{ item.detail }}
                </p>
              </div>
              <div class="msg">
                <div>
                  <p class="price">¥{{ item.price / 100 }}</p>
                  <p class="marketPrice">{{ item.originPriceStr }}</p>
                </div>
                <div>
                  <p class="favourable">返¥{{ item.commission / 100 }}</p>
                  <p class="merchantName">{{ item.merchantName }}</p>
                </div>
                <!-- <p class="star">
            <span>大众点评</span>
            <van-rate
              v-model="value"
              :size="12"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
            />
          </p> -->
              </div>
              <div
                :class="
                  item.status === 0
                    ? 'tip bg1'
                    : item.status === 1
                    ? 'tip bg2'
                    : 'tip bg2'
                "
              >
                <p>
                  {{
                    item.status === 0
                      ? "抢购中"
                      : item.status === 1
                      ? "已售完"
                      : "已下架"
                  }}
                </p>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <van-popup v-model="show">
      <div class="msg">
        <img :src="popImgUrl" style="width: 300px" alt="" />
      </div>
    </van-popup>
    <tabBar :shopAlias="shopMsg.shopAlias" />
    <backTop />
    <customerService />
    <baidu-map class="bm-view" @ready="handler"></baidu-map>
  </div>
</template>

<script>
import {
  getlyList,
  getAllCity,
  // extendBanner,
  getShopName,
  addFavorite,
  deleteFavorite,
} from "../../utils/api";
// import getCurrentCityName from "../../../static/js/getUserLocation";
import tabBar from "../../component/tabBar";
import backTop from "../../component/backTop";
import customerService from "../../component/customerService";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
export default {
  components: { tabBar, backTop, customerService, BaiduMap },
  data() {
    return {
      value: 3,
      pageNo: 1,
      pageSize: 15,
      keyword: "",
      lyData: null,
      isDownLoading: false,
      isUpLoading: false,
      upFinished: false,
      locationCity: {},
      extendData: null,
      shopId: "",
      shopMsg: {},
      show: false,
      popImgUrl: "",
      readLocationCity: {},
    };
  },
  created() {
    // this.shopId = window.location.pathname.split("/")[1];
  },
  async mounted() {
    // await this.extendBanner();
  },
  methods: {
    handler({ BMap }) {
      let that = this;
      var myCity = new BMap.LocalCity();

      myCity.get(async function (result) {
        that.readLocationCity = result;
        await that.getshopName();
        let obj = JSON.parse(sessionStorage.getItem("checkCity"));
        if (obj) {
          that.locationCity = obj;
          await that.getlyList();
        } else {
          console.log("12313213132313213");
          await that.getAllCity();
        }
      });
    },
    //收藏
    collection(item) {
      let params = {
        contentId: item.id,
        contentType: item.contentType,
        merchantId: item.merchantId,
        shopId: this.shopId,
      };
      addFavorite(params).then((res) => {
        console.log(res);
        this.$toast.success("已收藏");
        this.getlyList();
        item.isFavorite = true;
      });
    },
    //取消收藏
    cancelCollection(item) {
      let params = {
        shopId: this.shopId,
        favoriteId: item.favoriteId,
        contentId: item.id,
        contentType: item.contentType,
      };
      deleteFavorite(params).then((res) => {
        console.log(res);
        this.$toast.success("已取消收藏");
        this.getlyList();
        item.isFavorite = false;
      });
    },
    //获取店铺信息
    async getshopName() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      await getShopName().then((res) => {
        this.shopMsg = res;
        this.shopId = res.id;
      });
    },
    //联联跳转
    lianlian() {
      this.popImgUrl = this.shopMsg.lianlianTeamUrl;
      this.show = true;
      // window.location.href = this.shopMsg.lianlianTeamUrl;
    },
    //侠侣跳转
    xialv() {
      this.popImgUrl = this.shopMsg.xialvTeamUrl;
      this.show = true;
      // window.location.href = this.shopMsg.xialvTeamUrl;
    },
    //跳转推广位详情
    // jumpExtend(item) {
    //   window.location.href = item.url;
    // },
    //获取周边游推广位列表
    // async extendBanner() {
    //   this.$toast.loading({
    //     message: "加载中...",
    //     forbidClick: true,
    //     duration: 0,
    //   });
    //   let params = {
    //     shopId: this.shopId,
    //   };
    //   await extendBanner(params).then((res) => {
    //     this.extendData = res.data;
    //     this.$toast.clear();
    //   });
    // },
    //获取所有城市
    async getAllCity() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      await getAllCity().then((res) => {
        this.cityData = res.data;
        this.getCurrentCity();
      });
    },
    //定位
    getCurrentCity() {
      let that = this;
      return new Promise(function () {
        if (that.readLocationCity.name !== "全国") {
          let name = that.readLocationCity.name.split("市")[0];
          console.log("屁眼", name, "-------");
          that.cityData.map((item) => {
            item.item.map((item1) => {
              if (item1.city === name) {
                that.locationCity = item1;
                that.getlyList();
              }
            });
          });
        } else {
          console.log("皮炎");
          that.cityData.map((item) => {
            item.item.map((item1) => {
              if (item1.city === "北京") {
                that.locationCity = item1;
                that.getlyList();
              }
            });
          });
        }
        // getCurrentCityName().then((res) => {

        // });
      });
    },
    //搜索
    changeInput() {
      this.pageNo = 1;
      this.getlyList("search");
    },
    onDownRefresh() {
      this.pageNo = 1;
      this.isUpLoading = true;
      this.upFinished = false; // 不写这句会导致你上拉到底过后在下拉刷新将不能触发下拉加载事件
      this.getlyList("refresh"); // 加载数据
    },
    onLoad() {
      setTimeout(() => {
        this.pageNo++;
        this.getlyList();
      }, 1000);
    },
    //跳转详情
    jumpUrl(url) {
      window.location.href = url;
    },
    //获取周边游列表
    async getlyList(type) {
      let params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        cityCode: this.locationCity.cityCode,
        shopId: this.shopId,
      };

      if (this.keyword) {
        params.keyword = this.keyword;
      }
      if (this.pageNo === 1 && type !== "refresh") {
        // this.$toast.loading({
        //   message: "加载中...",
        //   forbidClick: true,
        //   duration: 0,
        // });
      }
      await getlyList(params)
        .then((res) => {
          console.log(res);
          let _this = this;
          setTimeout(function () {
            _this.$toast.clear();
          }, 150);

          if (this.pageNo === 1) {
            this.lyData = res.data;
          } else {
            res.data.map((item) => {
              this.lyData.push(item);
            });
          }
          if (this.lyData == null || this.lyData.length === 0) {
            // 加载结束
            this.upFinished = true;
            return;
          }
          if (this.lyData.length < this.pageSize) {
            // 最后一页不足10条的情况
            this.upFinished = true;
          }

          this.isUpLoading = false;
        })
        .finally(() => {
          this.isDownLoading = false;
          this.isUpLoading = false;
        });
    },
    //跳转城市列表
    cityList() {
      this.$router.push({
        path: `/${this.shopId}/city`,
        query: {
          name: this.locationCity.city,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #ff8757;

/deep/ .van-search .van-cell {
  padding: 2px;
}
/deep/ .van-search {
  padding: 0;
  border-radius: 4px !important;
}
/deep/ .van-search__content {
  height: 32px;
  background: #fcfcfc;
  border-radius: 16px;
  border: 1px solid #eeeeee;
}
/deep/ .van-field__left-icon {
  display: none;
}
/deep/ .van-cell {
  width: 100%;
  padding: 5px;
  border-radius: 7px;
}

#recreation {
  width: 100%;
  // background: #f2f2f2;
  padding-bottom: 60px;
  padding: 15px 15px 60px;
}
.tipDiv {
  width: 100%;
  padding: 10px 0 0;
  .tips {
    width: 100%;
    // background: $primary-color;
    height: 24px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    /deep/ .van-icon {
      transform: rotate(180deg);
    }
    p {
      font-size: 12px;
      line-height: 12px;
      color: #fff;
      padding: 0 5px;
    }
  }
}

.search {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /deep/ .van-field__body {
    margin-top: 2px;
  }
  .city {
    width: 60px;
    display: flex;
    align-items: center;

    p {
      margin-left: 2px;
      font-size: 12px;
      line-height: 12px;
      color: #333;
      margin: 0 2px;
      white-space: nowrap;
      width: 30px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .input {
    width: 100%;
    flex: 1;
    margin-left: 10px;
  }
}
.extendDiv {
  width: 100%;
  padding: 10px 10px;
  background: $primary-color;
  border-radius: 10px;
  margin-top: 15px;
}
.extend {
  width: 100%;
  display: flex;

  justify-content: space-between;
  div {
    width: 48%;
    img {
      width: 100%;
      cursor: pointer;
    }
  }
}
.list {
  width: 100%;
  padding: 15px 1px 0;
  // margin-bottom: 30px;
  .bg1 {
    background: $primary-color;
  }
  .bg2 {
    background: #999;
  }
  // .bg3 {
  //   background: rgb(248, 128, 128);
  // }
  .item {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    position: relative;
    .tip {
      position: absolute;
      z-index: 100;
      top: 30px;
      left: 25px;
      width: 56px;
      height: 17px;
      // background: $primary-color;
      border-radius: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 12px;
        line-height: 12px;
        color: #fff;
      }
    }
    .image {
      width: 100%;
      position: relative;
      img {
        width: 100%;
        border-radius: 7px;
        background: #f2f2f2;
        margin-top: 1.3px;
      }
      .collectionDiv {
        position: absolute;
        bottom: 5px;
        left: 8px;
        z-index: 10;
        display: flex;
        align-items: flex-start;
        img {
          width: 24px;
          background: none;
        }
        p {
          font-size: 12px;
          font-weight: bold;
          color: #ffffff;
          line-height: 17px;
          margin-left: 5px;
          margin-top: 1px;
        }
      }
    }
    .status1 {
      color: #333;
    }
    .status2 {
      color: #999;
    }
    .title {
      width: 100%;
      margin-top: 10px;
      text-align: justify;
      p {
        font-size: 14px;
        line-height: 20px;
        // color: #333;
        font-weight: 600;
      }
    }
    .name {
      width: 100%;
      margin-top: 10px;
      text-align: justify;
      p {
        font-size: 12px;
        line-height: 17px;
        // color: #333;
      }
    }
    .msg {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        display: flex;
        align-items: center;
        .price {
          font-size: 16px;
          line-height: 16px;
          color: $primary-color;
        }
        .marketPrice {
          font-size: 12px;
          line-height: 12px;
          text-decoration: line-through;
          color: #999;
          margin-left: 5px;
        }
        .favourable {
          height: 17px;
          background: $primary-color;
          border-radius: 9px;
          margin-right: 20px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          font-size: 12px;
          line-height: 12px;
        }
        .merchantName {
          font-size: 12px;
          line-height: 12px;
        }
        .star {
          display: flex;
          align-items: center;
          span {
            font-size: 12px;
            line-height: 12px;
            color: #333;
          }
        }
      }
      p {
        // margin-right: 20px;
      }
    }
  }
}
</style>