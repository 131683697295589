<template>
  <div>
    <div class="customer" id="customer" is-link @click="showPopup">
      <img src="../assets/call.png" alt="" />
    </div>
    <div>
      <van-popup v-model="show">
        <div class="msg">
          <!-- <p>{{shopMsg.shopkeeperContact}}</p> -->
          <img :src="shopMsg.shopkeeperContact" alt="">
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      shopMsg:{}
    };
  },
  mounted() {
    this.shopMsg = JSON.parse(localStorage.getItem('shopMsg'))
  },
  methods: {
    //展示联系方式
    showPopup() {
      this.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #ff804d;
.msg{
  padding: 10px;
  img{
    width: 100%;
  }
}
#customer {
  position: fixed;
  right: 10px;
  bottom: 170px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $primary-color;
  border-radius: 20px;
  cursor: pointer;
  img {
    width: 20px;
    height: 18px;
  }
  
}
</style>