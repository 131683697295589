<template>
  <div class="home-nav">
    <van-tabbar route active-color="#FF804D">
      <van-tabbar-item :to="`/${shopAlias}/home`">
        <span>商城</span>
        <template #icon="props">
          <img :src="props.active ? shopicon.active : shopicon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item :to="`/${shopAlias}/recreation`">
        <span>玩耍</span>
        <template #icon="props">
          <img :src="props.active ? playicon.active : playicon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item :to="`/${shopAlias}/me`">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? myicon.active : myicon.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  props: ["shopAlias"],
  data() {
    return {
      ipnonex: null,
      shopicon: {
        active: require("../assets/shop-active.svg"),
        inactive: require("../assets/shop.svg"),
      },
      playicon: {
        active: require("../assets/play-active.svg"),
        inactive: require("../assets/play.svg"),
      },
      myicon: {
        active: require("../assets/my-active.svg"),
        inactive: require("../assets/my.svg"),
      },
    };
  },
  created() {
    this.ipnonex = this.isIPhoneX();
  },
  methods: {
    isIPhoneX() {
      var u = navigator.userAgent;
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        if (screen.height == 812 && screen.width == 375) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-nav {
  width: 100%;
}
.van-tabbar {
  z-index: 200;
}

// @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
//   .van-tabbar {
//     height: 84px;
//     padding-bottom: 34upx;
//   }
//   .van-tabbar-item {
//     justify-content: flex-start;
//     margin-top: 10px;
//   }
// }
// @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
//   .van-tabbar {
//     height: 84px;
//     padding-bottom: 34upx;
//   }
//   .van-tabbar-item {
//     justify-content: flex-start;
//     margin-top: 10px;
//   }
// }

// @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
//   .van-tabbar {
//     height: 84px;
//     padding-bottom: 34upx;
//   }
//   .van-tabbar-item {
//     justify-content: flex-start;
//     margin-top: 10px;
//   }
// }
</style>