<template>
  <div class="backTop" id="backTop" v-if="btnFlag">
    <img src="../assets/up.png" @click="backTop" alt="">
  </div>
</template>

<script>
export default {
  data() {
    return {
      btnFlag: false,
    };
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
  },
  methods: {
    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 200) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
    destroyed() {
      window.removeEventListener("scroll", this.scrollToTop);
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #FF804D;
#backTop {
  position: fixed;
  right: 10px;
  bottom: 220px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $primary-color;
  border-radius: 20px;
  cursor: pointer;
  img{
    width: 16px;
    height: 16px;
  }
}
</style>